
import React from "react";
import Layout from "../components/layout/Layout";
import { Button } from "../components/Button";

export default ({ location }) => {
    return (
        <Layout title="Thanks for signing up to get the ICC whitepaper!" location={location}>
            <main id="content">
                <section className="hero is-fullheight-with-navbar">
                    <div className="hero-body">
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-9 is-offset-2">
                                    <h1>Thanks for signing up to get the ICC whitepaper! Get the ICC whitepaper PDF <a target="_blank" href="https://campaign.frends.com/hubfs/2021_ICC-white-paper_frends%20.pdf">here</a>!</h1>
                                    <Button color="purple" type="outlined" to="/" text="go back home" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    )
}